import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'registeredCasts';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({

    availableCastsSorted: (state, getters, rootState, rootGetters) => {

        const data = [];
        const registeredCastsSorted = rootGetters['registeredCasts/sorted'];

        if (Array.isArray(registeredCastsSorted) && registeredCastsSorted.length > 0) {
            registeredCastsSorted.forEach((item) => {
                if (item.pairedWith.length === 0) {
                    data.push(item)
                }
            });
        }

        return data;
    },
    sortedWithConnectivity: (state, getters, rootState, rootGetters) => {
        const data = [];
        const registeredCastsSorted = rootGetters['registeredCasts/sorted'];

        const signalNoiseLevel = function(item) {
            if (item.chromecast.configuration.ethernet_connected) {
                return "N/A";
            }

            const signal = item.chromecast.configuration.signal_level;
            const noise = item.chromecast.configuration.noise_level;
            const diff = Math.abs(signal - noise);

            let signalColor = "";
            let signalPresentation = signal;
            if (signal === 0) {
                signalPresentation = "?";
            } else {
                if(signal < -70) { signalColor = "red"; } // Signal is weaker requirement
                else if(signal < -65) { signalColor = "orange"; } // Signal is weaker requirement
            }

            let noiseColor = "";
            let noisePresentation = noise;
            if (noise === 0) {
                noisePresentation = "?";
            } else {
                if (diff < 15) { noiseColor = "red"; } // Diff is less than 15
                else if (diff < 18) { noiseColor = "orange"; } // Diff is less than 15
            }

            return "<span style='color: "+signalColor+"'>" + signalPresentation + "</span> / <span style='color: "+noiseColor+"'>" + noisePresentation + "</span>"
        }

        const client = function(item) {
            if (item.pairedWith) {
                return item.pairedWith.length === 0 ? " - " : item.pairedWith.length;
            } else {
                return " - ";
            }
        }

        if (Array.isArray(registeredCastsSorted) && registeredCastsSorted.length > 0) {
            registeredCastsSorted.forEach((item) => {
                item.chromecast.connection = item.chromecast.configuration.ethernet_connected ? "Ethernet" : "Wi-Fi";
                item.signalNoiseLevel = signalNoiseLevel(item);
                item.client = client(item);
                const md = _.get(item, 'chromecast.properties.md', '');
                const isPhilips = md.startsWith('TPM');
                const readOnlyModeIsSet = item.chromecast.readOnlyMode !== undefined && item.chromecast.readOnlyMode !== null;
                if (isPhilips) {
                    item.configurable = 'not-applicable'
                } else if (readOnlyModeIsSet) {
                    item.configurable = (item.chromecast.readOnlyMode ? 'not-configurable' : 'configurable');
                } else {
                    item.configurable = 'unknown';
                }
                item.connectivity = isPhilips ?
                    (item.chromecast.available ? 'online' : 'idle') :
                    (item.chromecast.available ? 'online' : 'offline');
                data.push(item)
            });
        }

        return data;
    }

});

export default module;