<template>
    <v-app-bar
            :clipped-left="true"
            class="the-app-bar"
            :dense="!$app.portalThemeActive($app.portalThemes.THEME_PPDS)"
            app
    >
        <v-row dense v-if="$vuetify.breakpoint.mdAndDown">
            <v-icon style="margin-right: 8px;" @click="$app.drawer = true" v-if="!homeMode">
                mdi-dots-grid
            </v-icon>
            <v-icon style="margin-right: 8px;" @click="goHome" v-if="!homeMode">
                mdi-home
            </v-icon>
        </v-row>
        <MainMenuContextSelector v-else-if="$store.state.context.switchingAvailable"></MainMenuContextSelector>

        <v-divider v-if="!$vuetify.breakpoint.mdAndDown && $store.state.context.switchingAvailable" vertical inset></v-divider>

        <BaseSizedBox v-if="$app.portalThemeActive($app.portalThemes.THEME_PPDS)" width="16"></BaseSizedBox>

        <div
            v-if="$app.portalThemeActive($app.portalThemes.THEME_PPDS)"
            @click="logoClick"
            class="app-bar-logo"
        >
            <img :src="require('/public/logo/PDDS_Wave_logo.png')" alt=""/>
        </div>

        <v-icon v-if="!$vuetify.breakpoint.mdAndDown && !$app.portalThemeActive($app.portalThemes.THEME_PPDS)" style="margin-left: 8px;">{{icon}}</v-icon>

        <BaseSizedBox v-if="$app.portalThemeActive($app.portalThemes.THEME_PPDS)" width="13"></BaseSizedBox>

        <v-toolbar-title class="truncate" style="padding-left: 8px;" v-html="heading"></v-toolbar-title>

        <v-spacer></v-spacer>

        <v-toolbar-title style="padding-right: 16px;" v-if="!$vuetify.breakpoint.mdAndDown" data-testid="appBarName">{{userName}}</v-toolbar-title>

        <v-divider vertical inset></v-divider>

        <JobList v-if="jobsToDisplay > 0"></JobList>

        <MainMenuHelp
            v-if="!$app.portalThemeActive($app.portalThemes.THEME_PPDS)"
        ></MainMenuHelp>

        <MainMenuManagementMessages></MainMenuManagementMessages>

        <MainMenuLanguageSelector
                v-if="!$app.portalThemeActive($app.portalThemes.THEME_PPDS)"
        ></MainMenuLanguageSelector>

        <v-tooltip v-if="false" bottom>
            <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on"><v-icon>notifications</v-icon></v-btn>
            </template>
            <span>{{$t('appbar.notifications')}}</span>
        </v-tooltip>

        <MainMenuUser></MainMenuUser>

        <v-img
            v-if="($app.portalThemeActive($app.portalThemes.THEME_UNIGUEST) || $app.portalThemeActive($app.portalThemes.THEME_DEFAULT)) && $app.desktop"
            :src="require('/public/logo/Hub_Logo.svg')"
            class="uniguest-logo"
            contain
        >
        </v-img>

    </v-app-bar>
</template>

<script>
import MainMenuContextSelector from '../mainmenu/MainMenuContextSelector.vue';
import MainMenuLanguageSelector from '../mainmenu/MainMenuLanguageSelector.vue';
import MainMenuUser from '../mainmenu/MainMenuUser.vue';
import MainMenuHelp from '../help/MainMenuHelp.vue';
import MainMenuManagementMessages from '../mainmenu/MainMenuManagementMessages.vue';
import { mapGetters } from 'vuex';
import BaseSizedBox from '@/components/base/BaseSizedBox.vue';
import JobList from '@/components/jobmanagement/JobList.vue';

export default {
        name: 'TheAppBar',
        components: {
            JobList,
            BaseSizedBox,
            MainMenuHelp,
            MainMenuUser,
            MainMenuContextSelector,
            MainMenuLanguageSelector,
            MainMenuManagementMessages
        },
        data() {
            return {
                routes: [],
                currentPath: null,
                destroy: false
            }
        },
        computed: {
            ...mapGetters({
                jobs: 'jobs/sorted'
            }),
            jobsToDisplay() {
                return this.jobs.filter(job => { return !job.deleted }).length;
            },
            homeMode() {
                return this.$store.state.context.mode === 'home' || this.$store.state.context.mode === null || this.$store.state.context.mode === 'loading';
            },
            icon() {
                switch(this.$store.state.context.mode) {
                    case 'theme':
                        return 'mdi-package-variant'
                    case 'site':
                        return 'mdi-office-building-marker'
                    case 'sharing':
                        return 'mdi-share-variant'
                    case 'content provider':
                        return 'mdi-share-variant-outline'
                }
                return 'home';
            },
            heading() {
                let heading = this.$t('generic.home');
                if(!this.$app.desktop) {
                    if(this.$route.name) {
                        heading = this.$t('router.' + this.$route.name);
                    }
                    return heading;
                }
                if (this.$store.state.context.mode === 'site' || this.$store.state.context.mode === 'theme' || this.$store.state.context.mode === 'sharing' || this.$store.state.context.mode === 'content provider') {
                    const selectedContext = this.$store.state.context.selectedContext;

                    if (selectedContext) {
                        heading = (selectedContext.config !== null && selectedContext.config.demo === true) ? "[DEMO] " : "";
                        heading += selectedContext.name;
                        heading += (selectedContext.config !== null && selectedContext.config.watermark === 'license-inactive') ? " (License Inactive)" : "";
                    }
                }
                if(this.$vuetify.breakpoint.mdAndDown) {
                    return heading;
                }
                try {
                    if (this.$route.meta.group.nameKey) {
                        heading += '<span class="mdi mdi-chevron-right"></span> ' +  this.$t('router.' + this.$route.meta.group.nameKey);
                    }
                } catch(e) {
                }
                if(this.$route.name) {
                    heading += '<span class="mdi mdi-chevron-right"></span> ' + this.$t('router.' + this.$route.name);
                }
                return heading;
            },
            userName() {
                if(this.$store.state.userAccount.account.contactInformation) {
                    return this.$store.state.userAccount.account.contactInformation.name;
                }
                return "";
            }
        },
        mounted() {
            if(this.$store.state.context.switchingAvailable) {
                this.$helpMessage.show('contextSwitcher', this.$t('drawer.contextButtonHelp'),
                    'desktop', {id: 'contextSelectorButton', icon: 'arrow_upward', pos: {top: '40px'}});
            }
        },
        watch: {},
        methods: {
            logoClick() {
                if (this.$router.history.current.path !== '/') {
                    this.$router.push('/');
                }
            },
            async goHome() {
                await this.$store.dispatch('context/clearContext');
            }
        }
    }
</script>

<style lang="scss" scoped>
    .v-app-bar .v-btn {
        margin: 0 !important;
    }

    .app-bar-logo:hover {
        cursor: pointer;
    }

    .ppds-theme {
        .the-app-bar {
            font-family: 'Gilroy', sans-serif !important;
            height: 72px;
            background-color: white !important;
        }
    }

    .uniguest-theme {
        .uniguest-logo {
            position: absolute;
            left: 0;
            right: 0;
            height: 30px;
            width: 125px;
            margin: 0 auto;
        }

        .truncate {
            width: 39%;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
</style>
