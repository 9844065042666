import BaseCrudModule from '@/store/modules/base/BaseCrudModule';
import apis from '@/lib/api/index';

const thisModule = 'management';

const module = new BaseCrudModule(apis[thisModule], '');

module.addGetters({
    byUpcoming: (state, getters) => {
        return state.items
            .filter(function (item) {
                return new Date(item.toUtc) > new Date();
            })
    },
    bySeverity: (state, getters) => (severity) => {
        const severityLower = severity.toLowerCase();
        return getters.sorted.filter(msg => {
            return msg.severity.toLowerCase() === severityLower;
        });
    },
});

export default module;
