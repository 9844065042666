import axios from 'axios';
import Validator from '@/lib/helpers/Validator';
import AuthManager from '@/lib/helpers/AuthManager';
import UrlManager from '@/lib//helpers/UrlManager';

axios.defaults.timeout = 30000;

let contextId = null;

class BasicCrudApi {

    constructor(config) {
        this.itemType = config.itemType;
        this.contextFiltered = config.contextFiltered;

        this.baseUrl = '';
        if (config.feature !== null) {
            this.baseUrl = UrlManager.buildApiV1Url(config.feature, config.resource, config.subPath);
        }
    }

    static setContextId(id) {
        contextId = id;
    }

    appendContextParam(url, customContextId = null) {
        if(customContextId) {
            return url + ((url.includes("?")) ? "&" : "?") + "contextId=" + customContextId;
        } else if(contextId) {
            return url + ((url.includes("?")) ? "&" : "?") + "contextId=" + contextId;
        } else {
            return url;
        }
    }

    getCompleteUrl(pathExtension, appendCtx = false, customContextId = null) {
        let url = this.baseUrl;
        if (Validator.isNotEmpty(pathExtension)) {
            if (this.baseUrl.endsWith('/') && pathExtension.startsWith('/')) {
                pathExtension = pathExtension.substring(1);
            }
            url += pathExtension;
        }
        if(customContextId !== null) {
            return this.appendContextParam(url, customContextId);
        }
        return appendCtx ? this.appendContextParam(url) : url;
    }

    addAuthenticationHeaders(requestConfig = {}) {
        requestConfig.headers = {
            ...requestConfig.headers,
            ...AuthManager.getAuthenticationHeader()
        };
    }

    createErrorResponse(error, operation) {
        console.error(error);

        const response = {
            status: 400,
            data: `${this.itemType.capitalize()} ${operation} failed`
        };

        if (Validator.isNotEmpty(error) && Validator.isNotEmpty(error.response)) {
            response.status = parseInt(error.response.status) > 400 ? parseInt(error.response.status) : 400;

            if (Validator.isNotEmpty(error.response.data) /* && error.response.data.length < 80 */) {
                response.data = error.response.data;
            }
        }

        return response
    }

    getApiName() {
        return this.itemType;
    }

    get(pathExtension, requestConfig = {}, customContextId) {
        this.addAuthenticationHeaders(requestConfig);

        return axios.get(this.getCompleteUrl(pathExtension, this.contextFiltered, customContextId), requestConfig).catch((error) => {
            return this.createErrorResponse(error, 'FETCH');
        });
    }

    patch(data, id, requestConfig = {}, appendContextId = false) {
        let url = this.baseUrl + '/' + id;
        this.addAuthenticationHeaders(requestConfig);
        if(appendContextId === true) {
            url = this.appendContextParam(url);
        }

        return axios.patch(url, data, requestConfig).catch((error => {
            return this.createErrorResponse(error, 'PATCH');
        }));
    }

    patchMultiple(data, requestConfig = {}) {
        this.addAuthenticationHeaders(requestConfig);

        return axios.patch(this.baseUrl + '/multiple', data, requestConfig).catch((error => {
            return this.createErrorResponse(error, 'multiple PATCH')
        }));
    }

    put(data, id, requestConfig = {}, appendContextId = false) {
        let url = this.baseUrl + '/' + id;
        this.addAuthenticationHeaders(requestConfig);
        if(appendContextId === true) {
            url = this.appendContextParam(url);
        }

        return axios.put(url, data, requestConfig).catch((error => {
            return this.createErrorResponse(error, 'PUT');
        }));
    }

    putWithPathExtension(data, id, pathExtension = '', requestConfig = {}, appendContextId = false) {
        let url = `${this.baseUrl}/${id}${pathExtension}`;
        this.addAuthenticationHeaders(requestConfig);
        if (appendContextId === true) {
            url = this.appendContextParam(url);
        }

        return axios.put(url, data, requestConfig).catch((error => {
            return this.createErrorResponse(error, 'PUT');
        }));
    }

    putMultiple(data, requestConfig = {}) {
        this.addAuthenticationHeaders(requestConfig);

        return axios.put(this.baseUrl + '/multiple', data, requestConfig).catch((error => {
            return this.createErrorResponse(error, 'multiple PUT')
        }));
    }

    post(data, pathExtension, requestConfig = {}) {
        this.addAuthenticationHeaders(requestConfig);

        return axios.post(this.getCompleteUrl(pathExtension), data, requestConfig).catch(error => {
            return this.createErrorResponse(error, 'POST')
        });
    }

    postMultiple(data, requestConfig = {}) {
        this.addAuthenticationHeaders(requestConfig);

        return axios.post(this.baseUrl + '/multiple', data, requestConfig).catch((error => {
            return this.createErrorResponse(error, 'multiple POST')
        }));
    }

    delete(id, pathExtension = '', requestConfig = {}) {
        this.addAuthenticationHeaders(requestConfig);

        return axios.delete(`${this.baseUrl}/${id + pathExtension}`, requestConfig).catch((error => {
            return this.createErrorResponse(error, 'DELETE')
        }));
    }

    deleteMultiple(data, requestConfig = {}) {
        this.addAuthenticationHeaders(requestConfig);

        return axios.delete(this.baseUrl + '/multiple',
            {...requestConfig, data: data}).catch((error => {
            return this.createErrorResponse(error, 'multiple DELETE')
        }));
    }
}

export default BasicCrudApi;
