import BasicCrudApi from '@/lib/api/BasicCrudApi'

/**
 * virtualResource
 * Since the feature / resource ambigous when using sub-paths, the virtualResource
 * defines the uniqe resource that is sent on the push-channel when the given API
 * is updated
 * moduleName
 * If the store-modules name does not match the apiName, then a moduleName property
 * should be set with the proper matching key
 */
export const apiConfigurations = [
    {
        feature: "Authorization",
        resource: "Sessions",
        itemType: 'Authentication',
        apiName: 'session',
        contextFiltered: false
    },
    {
        feature: "AccessManagement",
        resource: "Roles",
        itemType: 'Role',
        apiName: 'roles',
        contextFiltered: true
    },
    {
        feature: "AccessManagement",
        resource: "PrivilegeSets",
        itemType: 'PrivilegeSet',
        apiName: 'privilegeSets',
        contextFiltered: true
    },
    {
        feature: "AccountManagement",
        resource: "Accounts",
        itemType: 'Account',
        apiName: 'accounts',
        contextFiltered: true
    },
    {
        feature: "AccountManagement",
        resource: "ApiAccounts",
        itemType: 'Access',
        apiName: 'apiAccounts',
        contextFiltered: false
    },
    {
        feature: "AccessManagement",
        resource: "Access",
        itemType: 'Access',
        apiName: 'accountAccesses',
        contextFiltered: false
    },
    {
        feature: "AccessManagement",
        resource: "ContextAccess",
        itemType: 'Access',
        apiName: 'contextAccess',
        contextFiltered: true
    },
    {
        feature: "AccountManagement",
        resource: "AccessDirect",
        itemType: 'Access',
        apiName: 'contextAccountsDirect',
        contextFiltered: true
    },
    {
        feature: "AccountManagement",
        resource: "AccessManagedBy",
        itemType: 'Access',
        apiName: 'contextAccountsManagedBy',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "Contexts",
        itemType: 'Context',
        apiName: 'contexts',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "SystemContextConfigurations",
        itemType: 'Context Configuration',
        apiName: 'systemContextConfigs',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "Deployments",
        subPath: 'language-info',
        itemType: 'Language',
        apiName: 'languageInfoDeployment',
        moduleName: 'languageInfo',
        contextFiltered: true
    },
    {
        feature: "ContextManagement",
        resource: "Themes",
        subPath: 'language-info',
        itemType: 'Language',
        apiName: 'languageInfoTheme',
        contextFiltered: true
    },
    {
        feature: "ContextManagement",
        resource: "ContentProviderContexts",
        subPath: 'language-info',
        itemType: 'Language',
        apiName: 'languageInfoContentProvider',
        contextFiltered: true
    },
    {
        feature: "ContextManagement",
        resource: "Themes",
        itemType: 'Theme',
        apiName: 'themes',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "Deployments",
        itemType: 'Deployment',
        apiName: 'deployments',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "LegacyContexts",
        itemType: 'Legacies',
        apiName: 'legacies',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "Resellers",
        itemType: 'Reseller',
        apiName: 'resellers',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "Distributors",
        itemType: 'Distributors',
        apiName: 'distributors',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "SharingContexts",
        itemType: 'SharingContext',
        apiName: 'sharingContexts',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "ContentProviderContexts",
        itemType: 'ContentProviderContext',
        apiName: 'contentProviderContexts',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "Systems",
        itemType: 'System',
        apiName: 'systems',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "Domains",
        itemType: 'Domains',
        apiName: 'domains',
        contextFiltered: false
    },
    {
        feature: "AccessManagement",
        resource: "Owners",
        itemType: 'Owner',
        apiName: 'owners',
        contextFiltered: true
    },
    {
        feature: "ContextManagement",
        resource: "TimeZones",
        itemType: 'Timezone',
        apiName: 'timeZones',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "SystemConfig",
        itemType: 'System Config',
        apiName: 'systemConfig',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: "Shares",
        itemType: 'Share',
        apiName: 'shares',
        contextFiltered: true
    },
    {
        feature: "ContextManagement",
        resource: "Snapshots",
        itemType: 'Snapshot',
        apiName: 'snapshots',
        contextFiltered: true
    },
    {
        feature: "AccessManagement",
        resource: "Areas",
        itemType: 'Area',
        apiName: 'areas',
        contextFiltered: true
    },
    {
        feature: "ContextManagement",
        resource: "FeatureConfigurations",
        itemType: 'Context management service config',
        apiName: 'contextManagementServiceConfig',
        contextFiltered: true
    },
    {
        feature: "ContextManagement",
        resource: "ContextTypes",
        itemType: 'Context Type',
        apiName: 'contextTypes',
        contextFiltered: false
    },
    {
        feature: "Clients",
        resource: "Clients",
        itemType: 'Client',
        apiName: 'clients',
        contextFiltered: true
    },
    {
        feature: "Clients",
        resource: "ClientStatuses",
        itemType: 'Client Status',
        apiName: 'clientStatuses',
        contextFiltered: true
    },
    {
        feature: "Clients",
        resource: "Clients",
        subPath: "push-message",
        itemType: 'Push message',
        apiName: 'pushMessage',
        contextFiltered: false
    },
    {
        feature: "Clients",
        resource: "InstallCodes",
        itemType: 'Install Code',
        apiName: 'installCodes',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "CompositeMenuPages",
        itemType: 'Template Menu Page',
        apiName: 'compositeMenuPages',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "InteractiveMenuPages",
        itemType: 'Interactive Menu Page',
        apiName: 'interactiveMenuPages',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "MenuLists",
        itemType: 'TV Data List',
        apiName: 'menuLists',
        contextFiltered: true
    },
    {
        feature: "ChannelTableManagement",
        resource: "MediaChannels",
        itemType: 'Media Channel',
        apiName: 'mediaChannels',
        contextFiltered: true
    },
    {
        feature: "ChannelTableManagement",
        resource: "ChannelTables",
        itemType: 'Channel Table',
        apiName: 'channelTables',
        contextFiltered: true
    },
    {
        feature: "ChannelTableManagement",
        resource: "ChannelGroups",
        itemType: 'Channel Table Group',
        apiName: 'channelTableGroups',
        contextFiltered: true
    },
    {
        feature: "ChannelTableManagement",
        resource: "Channels",
        itemType: 'Channel Table Channel',
        apiName: 'channelTableChannels',
        contextFiltered: true
    },
    {
        feature: "ChannelTableManagement",
        resource: "GlobalChannels",
        itemType: 'Global Media Channel',
        apiName: 'globalChannels',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "ConfigurationSchedules",
        itemType: 'Config Schedule',
        apiName: 'configSchedules',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "SchedulePlans",
        itemType: 'Schedule Plan',
        apiName: 'schedulePlans',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "ContentSchedules",
        itemType: 'Content Schedule',
        apiName: 'contentSchedules',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "ScreenConfigurations",
        itemType: 'screen setup',
        apiName: 'screenSetups',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "ScreenContents",
        itemType: 'screen content',
        apiName: 'screenContents',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "Screens",
        itemType: 'screen',
        apiName: 'screens',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "ScheduleRuleSets",
        itemType: 'screen schedule ruleset',
        apiName: 'scheduleRuleSets',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "CurrentContent",
        itemType: 'content',
        apiName: 'currentScreenContents',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "Playlists",
        itemType: 'Playlist',
        apiName: 'playlists',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'PlaylistFolders',
        itemType: 'playlist folder',
        apiName: 'playlistFolders',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "PlaylistConfigurations",
        itemType: 'Playlist Configuration',
        apiName: 'playlistConfigurations',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "TemplateSources",
        itemType: 'Template Source',
        apiName: 'templateSources',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "Templates",
        itemType: 'Template',
        apiName: 'templates',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "Documents",
        itemType: 'Document',
        apiName: 'documents',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "Tables",
        itemType: 'Table',
        apiName: 'tables',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "TableItems",
        itemType: 'Table Item',
        apiName: 'tableItems',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "TableSchemas",
        itemType: 'Table Schema',
        apiName: 'tableSchemas',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "TablesConfigurations",
        itemType: 'Tables Configuration',
        apiName: 'tablesConfigurations',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'DocumentFolders',
        itemType: 'document folder',
        apiName: 'documentFolders',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'TemplateFolders',
        itemType: 'template folder',
        apiName: 'templateFolders',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'CompositeMenuPageFolders',
        itemType: 'Template menu page folder',
        apiName: 'compositeMenuPageFolders',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'InteractiveMenuPageFolders',
        itemType: 'tv menu page folder',
        apiName: 'interactiveMenuPageFolders',
        contextFiltered: true
    },
    {
        feature: "ResourceManagement",
        resource: "Resources",
        itemType: 'resources',
        apiName: 'resources',
        contextFiltered: true
    },
    {
        feature: "Screens",
        resource: "FeatureConfigurations",
        itemType: 'Screen service config',
        apiName: 'screenServiceConfig',
        contextFiltered: true
    },
    {
        feature: "EventManagement",
        resource: "Events",
        itemType: 'Event management',
        apiName: 'events',
        contextFiltered: true
    },
    {
        feature: "EventManagement",
        resource: "FeatureConfigurations",
        itemType: 'Event service config',
        apiName: 'eventServiceConfig',
        contextFiltered: true
    },
    {
        feature: "Signage",
        resource: "FeatureConfigurations",
        itemType: 'Signage service config',
        apiName: 'signageServiceConfig',
        contextFiltered: true
    },
    {
        feature: "Clients",
        resource: "FeatureConfigurations",
        itemType: 'Client service config',
        apiName: 'clientServiceConfig',
        contextFiltered: true
    },
    {
        feature: 'Hospitality',
        resource: 'GuestAccounts',
        itemType: 'Guest',
        apiName: 'hospitalityGuests',
        moduleName: 'guests',
        contextFiltered: true
    },
    {
        feature: "Hospitality",
        resource: "RoomAccounts",
        itemType: 'Room Account',
        apiName: 'roomAccounts',
        contextFiltered: true
    },
    {
        feature: "Hospitality",
        resource: "Locations",
        itemType: 'Hospitality location',
        apiName: 'hospitalityLocations',
        contextFiltered: true
    },
    {
        feature: 'Hospitality',
        resource: 'GuestMessages',
        itemType: 'Guest Message',
        apiName: 'guestMessages',
        contextFiltered: true
    },
    {
        feature: 'Hospitality',
        resource: 'GuestMessageTemplates',
        itemType: 'Guest Message Templates',
        apiName: 'guestMessageTemplates',
        contextFiltered: true
    },
    {
        feature: 'WakeUp',
        resource: 'WakeUps',
        itemType: 'Wakeup',
        apiName: 'wakeups',
        contextFiltered: true
    },
    {
        feature: 'Hospitality',
        resource: 'FeatureConfigurations',
        itemType: 'Hospitality service config',
        apiName: 'hospitalityServiceConfig',
        contextFiltered: true
    },
    {
        feature: 'ContextLocations',
        resource: 'Locations',
        itemType: 'Location',
        apiName: 'locations',
        contextFiltered: true
    },
    {
        feature: 'ContextLocations',
        resource: 'LocationCategories',
        itemType: 'Location category',
        apiName: 'locationCategories',
        contextFiltered: true
    },
    {
        feature: 'ContextLocations',
        resource: 'LocationTags',
        itemType: 'Location tag',
        apiName: 'locationTags',
        contextFiltered: true
    },
    {
        feature: 'LicenseManagement',
        resource: 'Licenses',
        itemType: 'License',
        apiName: 'licenses',
        contextFiltered: false
    },
    {
        feature: 'LicenseManagement',
        resource: 'Products',
        itemType: 'Product',
        apiName: 'products',
        contextFiltered: false
    },
    {
        feature: 'LicenseManagement',
        resource: 'Products',
        subPath: 'available-features',
        itemType: 'feature',
        apiName: 'features',
        contextFiltered: false
    },
    {
        feature: 'ResourceManagement',
        resource: 'Images',
        itemType: 'Image',
        apiName: 'images',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'Logos',
        itemType: 'logo',
        apiName: 'logos',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'WayfindingIcons',
        itemType: 'wayfinding icon',
        apiName: 'wayfindingIcons',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'Fonts',
        itemType: 'Font',
        apiName: 'fonts',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'Videos',
        itemType: 'Video',
        apiName: 'videos',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'Folders',
        itemType: 'Folder',
        apiName: 'resourceFolders',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'LogoFolders',
        itemType: 'logo folder',
        apiName: 'logoFolders',
        contextFiltered: true
    },
    {
        feature: 'ResourceManagement',
        resource: 'WayfindingIconFolders',
        itemType: 'wayfinding icon folder',
        apiName: 'wayfindingIconFolders',
        contextFiltered: true
    },
    {
        feature: "ChannelTableManagement",
        resource: 'WebRadioChannels',
        itemType: 'WebRadio Channel',
        apiName: 'webRadios',
        contextFiltered: false
    },
    {
        feature: "Weather",
        resource: 'Forecasts',
        itemType: 'Weather Forecast',
        apiName: 'weather',
        contextFiltered: true
    },
    {
        feature: "Overview",
        resource: 'Contexts',
        itemType: 'Context overview',
        apiName: 'overviewContexts',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: 'VendorConfigurations',
        itemType: 'Vendor configuration',
        apiName: 'vendorConfigurations',
        contextFiltered: false
    },
    {
        feature: "Notification",
        resource: 'NotificationGroups',
        itemType: 'Notification Groups',
        apiName: 'notificationGroups',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: 'CurrentVendorConfiguration',
        itemType: 'Vendor configuration',
        apiName: 'currentVendorConfiguration',
        contextFiltered: false
    },
    {
        feature: "ContextManagement",
        resource: 'SharedContexts',
        itemType: 'Shared Context',
        apiName: 'sharedContexts',
        contextFiltered: true
    },
    {
        feature: "Pms",
        resource: 'Vendors',
        itemType: 'PMS vendor',
        apiName: 'pmsVendors',
        contextFiltered: true
    },
    {
        feature: "Pms",
        resource: 'FeatureConfigurations',
        itemType: 'PMS configuration',
        apiName: 'pmsServiceConfig',
        contextFiltered: true
    },
    {
        feature: "Pms",
        resource: 'Bills',
        itemType: 'PMS Guest Bill',
        apiName: 'pmsBills',
        contextFiltered: true
    },
    {
        feature: "Pms",
        virtualResource: 'Status',
        resource: 'FeatureConfigurations',
        itemType: 'PMS Guest Bill',
        apiName: 'pmsStatus',
        subPath: 'status',
        contextFiltered: true
    },
    {
        feature: "Pms",
        resource: 'LogEntries',
        itemType: 'PMS Log Entry',
        apiName: 'pmsLogEntries',
        contextFiltered: true
    },
    {
        feature: "NetworkManagement",
        resource: 'Proxies',
        itemType: 'Network Proxy',
        apiName: 'networkProxies',
        contextFiltered: true
    },
    {
        feature: "AgentManagement",
        resource: 'ResourceServers',
        itemType: 'Resource Server Configuration',
        apiName: 'resourceServerConfigurations',
        contextFiltered: true
    },
    {
        feature: null,
        resource: null,
        itemType: 'unspecified',
        apiName: 'custom',
        contextFiltered: false
    },
    {
        feature: "LegacyContext",
        resource: "Statuses",
        itemType: 'LegacyStatus',
        apiName: 'legacyStatuses',
        contextFiltered: false
    },
    {
        feature: "CastManagement",
        resource: "RegisteredCasts",
        itemType: 'Registered Cast',
        apiName: 'registeredCasts',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "UnregisteredCast",
        itemType: 'Unregistered Cast',
        apiName: 'unregisteredCasts',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "ActiveCastSession",
        itemType: 'Active Cast Session',
        apiName: 'activeCastSessions',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "CastEventLog",
        itemType: 'Event Log',
        apiName: 'castEventLogs',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "CastStatus",
        itemType: 'Chromecast Proxy Server Status',
        apiName: 'chromecastProxyServerStatus',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "CastProxySignageStatus",
        itemType: 'Chromecast Proxy Server Signage Status',
        apiName: 'chromecastProxyServerSignageStatus',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "CastStatistics",
        itemType: 'Cast Statistics',
        apiName: 'castStatistics',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "CastProxyConfiguration",
        itemType: "Cast Proxy Configuration",
        apiName: "castProxyConfiguration",
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "AdvancedNetworkConfiguration",
        itemType: 'Advanced Network Configuration',
        apiName: 'advancedNetworkConfigurations',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "NetworkProxyConfiguration",
        itemType: 'Network Proxy Configuration',
        apiName: 'networkProxyConfigurations',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "ClientNetwork",
        itemType: 'Client Network',
        apiName: 'clientNetworks',
        contextFiltered: true
    },
    {
        feature: "CastManagement",
        resource: "ChromecastNetwork",
        itemType: 'Chromecast Network',
        apiName: 'chromecastNetworks',
        contextFiltered: true
    },
    {
        feature: "StreamAllocator",
        resource: "AllocatedStreams",
        itemType: "Allocated Stream",
        apiName: "allocatedStreams",
        contextFiltered: true
    },
    {
        feature: "StreamAllocator",
        resource: "StreamDevices",
        itemType: "Stream Device",
        apiName: "streamDevices",
        contextFiltered: true
    },
    {
        feature: 'StreamAllocator',
        resource: 'FeatureConfigurations',
        itemType: 'Stream Allocator service config',
        apiName: 'streamAllocatorServiceConfig',
        contextFiltered: true
    },
    {
        feature: 'StreamAllocator',
        resource: 'AllocationLogEntries',
        itemType: 'Allocation Log Entry',
        apiName: 'allocationLogEntries',
        contextFiltered: true
    },
    {
        feature: 'Logging',
        resource: 'LogEntries',
        itemType: 'Log Entry',
        apiName: 'logEntries',
        contextFiltered: false
    },
    {
        feature: 'JobManagement',
        resource: 'Jobs',
        itemType: 'Job',
        apiName: 'jobs',
        contextFiltered: true
    },
    {
        feature: "ContextManagement",
        resource: "Contexts",
        subPath: 'deleted',
        itemType: 'Deleted Context',
        apiName: 'deletedContexts',
        contextFiltered: false
    },
    {
        feature: "Clients",
        resource: "Clients",
        subPath: 'duplicate-registered',
        itemType: 'Duplicate Registered Client',
        apiName: 'duplicateRegisteredClients',
        contextFiltered: false
    },
    {
        feature: "EventManagement",
        resource: "ScreenWayfindingConfigurations",
        itemType: 'Screen Wayfinding Configuration',
        apiName: 'screenWayfindingConfigurations',
        contextFiltered: true
    },
    {
        feature: "EventManagement",
        resource: "Locations",
        itemType: 'Event location',
        apiName: 'eventLocations',
        contextFiltered: true
    },
    {
        feature: "EventManagement",
        resource: "IntegrationLocations",
        itemType: 'Integration location',
        apiName: 'integrationLocations',
        contextFiltered: true
    },
    {
        feature: "EventManagement",
        resource: "EventIntegrations",
        itemType: 'Event integration',
        apiName: 'eventIntegrations',
        contextFiltered: true
    },
    {
        feature: "EventManagement",
        resource: "EventIntegrationConfigurations",
        itemType: 'Event integration configuration',
        apiName: 'eventIntegrationConfigurations',
        contextFiltered: true
    },
    {
        feature: "EventManagement",
        resource: "ExternalLocations",
        itemType: "External location",
        apiName: "externalLocations",
        contextFiltered: true
    },
    {
        feature: "Remote",
        resource: "RemoteSessions",
        itemType: 'Virtual Remote Client Sessions',
        apiName: 'virtualRemoteClients',
        contextFiltered: true
    },
    {
        feature: 'Flights',
        resource: 'FlightLogos',
        itemType: 'Flight Logo',
        apiName: 'flightLogos',
        contextFiltered: false
    },
    {
        feature: 'Audit',
        resource: 'AuditLogs',
        itemType: 'Audit Log',
        apiName: 'auditLogs',
        contextFiltered: false
    },
    {
        feature: 'Audit',
        resource: 'Blacklist',
        itemType: 'Audit Log Blacklist',
        apiName: 'auditBlacklist',
        contextFiltered: false
    },
    {
        feature: "VariableManagement",
        resource: "Variables",
        itemType: 'Variable',
        apiName: 'variables',
        contextFiltered: true
    },
    {
        feature: 'Management',
        resource: 'SystemMessage',
        itemType: 'PortalMessage',
        apiName: 'management',
        contextFiltered: true
    },

];

const apiInstances = {};

apiConfigurations.forEach(apiConfig => { apiInstances[apiConfig.apiName] = new BasicCrudApi(apiConfig); });

export default apiInstances;
