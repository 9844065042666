import moment from 'moment';

class Validator {
    static get emailRegularExpression() {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    }

    static get ipAddressRegularExpression() {
        return /^((25[0-5]|(2[0-4]|1[0-9]|[1-9]|)[0-9])(\.(?!$)|$)){4}$/;
    }

    static get nameRegularExpression() {
        return /^[a-zA-Z0-9àáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒæÆČŠŽ∂ð '-]+$/;
    }

    static isEmpty(value) {
        switch (value) {
            // case 0:
            // case '0':
            case '':
            case null:
            case false:
            case undefined:
                return true;
            default:
                if (Array.isArray(value)) {
                    return value.length === 0;
                } else if (value.constructor === Object) {
                    return Object.entries(value).length === 0;
                } else {
                    return false;
                }
        }
    }

    static isStringOfMaxLength(value, maxLength) {
        if (!value || typeof value !== 'string') {
            return true;
        }

        return value.length <= maxLength;
    }

    static isEmptyOrBlank(value) {
        if (value === null || typeof value === 'undefined') {
            return true;
        } else {
            return this.isEmpty(value.trim());
        }
    }

    static isIdInDataUpdate(operation, id) {
        return (
            typeof operation === 'object' &&
            !this.isEmpty(operation.ids) &&
            operation.ids.indexOf(id) >= 0
        );
    }

    static isArray(value) {
        return Array.isArray(value);
    }

    static isNotEmpty(value) {
        return !Validator.isEmpty(value);
    }

    static isEmail(value) {
        return Validator.emailRegularExpression.test(value);
    }

    static isValidName(value) {
        return Validator.nameRegularExpression.test(value);
    }

    static isDataStale(fetched, staleLimit) {
        if (fetched === null) {
            return true;
        }

        const duration = moment.duration(
            fetched.diff(moment().subtract(staleLimit, 'minutes'))
        );
        return duration.asMinutes() >= staleLimit;
    }

    static isIpAddress(value) {
        if (!value) {
            return true;
        }
        return Validator.ipAddressRegularExpression.test(value);
    }

    static isIpPort(value) {
        if (!value) {
            return true;
        }
        const numberValue = Number(value);
        return numberValue > 0 && numberValue <= 65535;
    }

    static isFrequency(value) {
        if (!value) {
            return true;
        }
        const numberValue = Number(value);

        return numberValue > 0 && numberValue <= 99999999;
    }

    static arraysEqual(arr1, arr2) {
        if (!arr1 || !arr2) {
            return -1;
        }
        if (arr1.length !== arr2.length) return false;

        return arr1.every((obj1, index) => {
            const obj2 = arr2[index];
            return JSON.stringify(obj1) === JSON.stringify(obj2);
        });
    }

    // Checks major.minor.patch versioning, ignores -<CR/GA> suffix
    static isVersionSameOrHigher(versionToCheck, baseVersion) {
        if(!versionToCheck || !baseVersion) {
            return false;
        }

        const parseVersion = (version) => {
            const cleanVersion = version.split('-')[0];
            return cleanVersion.split('.').map(Number);
        };

        const [majorCheck, minorCheck, patchCheck] = parseVersion(versionToCheck);
        const [majorBase, minorBase, patchBase] = parseVersion(baseVersion);

        if (majorCheck > majorBase) {
            return true;
        } else if(majorCheck === majorBase) {
            if (minorCheck > minorBase) {
                return true;
            } else if(minorCheck === minorBase) {
                return patchCheck >= patchBase;
            }
        }

        return false;
    }
}

export default Validator;
