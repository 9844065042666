import Validator from '@/lib/helpers/Validator';
import ApiHelper from '@/lib/helpers/ApiHelper';
import moment from 'moment';
import Vue from 'vue';

const resCheck = ApiHelper.responseSuccessCheck;

class BaseCrudModule {
    constructor(api, fetchEndpoint = '', getters, cacheKey = null) {
        this.state = {
            processing: false,
            fetching: false,
            fetched: null,
            error: null,
            consecutiveErrorCnt: 0,
            items: [],
            change: null,
            cacheKey: (typeof cacheKey === 'string' && cacheKey.length > 0) ? 'storeCache.' + cacheKey : null
        };
        this.namespaced = true;

        this.api = api;

        this.getters = {
            fetchCompleted: (state) => {
                return (!state.fetching && (state.fetched !== null || state.error !== null));
            },
            isLoaded: (state) => {
                return (!state.fetching && (state.fetched !== null));
            },
            isLoading: (state) => {
                return state.fetching;
            },
            idMap: (state) => {
                return Map.createFromArray(state.items, 'id');
            },
            idMapBy: (state) => (field) => {
                return Map.createFromArray(state.items, field);
            },
            idMapByGetter: (state, getters) => (getter, field, asList = false) => {
                const data = typeof getter === 'string' ? getters[getter] : state.items;
                return Map.createFromArray(data, field ? field : 'id', asList);
            },
            itemById: (state, getters) => (id, getter, idField = 'id') => {
                const data = typeof getter === 'string' ? getters[getter] : state.items;
                return Map.retrieve(Map.createFromArray(data, idField), id);
            },
            filtered: (state, getters) => (field, val) => {
                const items = this.cloneItems().filter(item => {
                    return item[field] === val;
                });
                if(Array.isArray(items)) { Array.sort(items, 'name'); }
                return items;
            },
            withParsedTimestamp: (state, getters) => (field, sorted = '') => {
                const data = sorted === '' ? this.cloneItems() : getters['sortedBy'](field, sorted !== 'desc');
                if (Array.isArray(data)) {
                    data.forEach((item) => {
                        if(item.hasOwnProperty(field)) {
                            item[field + 'Obj'] = moment.utc(item[field]).local();
                        }
                    });
                    return data;
                }
                return [];
            },
            translated: () => {
                const data = this.cloneItems();
                if (Array.isArray(data)) {
                    data.forEach((item) => {
                        item.nameTranslated = Validator.isNotEmpty(item.name) ? item.name : '';
                        item.titleTranslated = Validator.isNotEmpty(item.title) ? Vue.prototype.$tm(item.title) : '';
                    });
                    return data;
                }
                return [];
            },
            translatedAndSortedBy: (state, getters) => (field, asc = true) => {
                const data = getters.translated;
                if (Array.isArray(data)) {
                    Array.sort(data, field, asc);
                    return data;
                }
                return [];
            },
            /**
             * WARNING - USE WITH CAUTION!!
             * Sort AFTER filtering!
             * @returns {*[]|*}
             */
            sorted: () => {
                const data = this.cloneItems();
                if (Array.isArray(data)) {
                    Array.sort(data, 'name');
                    return data;
                }
                return [];
            },
            sortedBy: () => (field, asc = true) => {
                const data = this.cloneItems();
                if (Array.isArray(data)) {
                    Array.sort(data, field, asc);
                    return data;
                }
                return [];
            }
        };

        if(getters) {
            this.addGetters(getters);
        }
        const state = this.state;

        this.actions = {
            fetch({commit}, {ignoreStale = false, ignoreError = false, pathExtension = '', requestConfig = {}, contextId = null} = {}) {
                return new Promise(async (resolve) => {
                    if (state.fetching) {
                        resolve();
                        return;
                    }

                    if (!Validator.isDataStale(state.fetched, 10) && !ignoreStale) {
                        resolve();
                        return;
                    }

                    commit('fetching');

                    if(state.cacheKey && state.fetched === null && sessionStorage[state.cacheKey]) {
                        //console.log("----------------------------------- FROM CACHE");
                        commit('fetchFromCacheSuccess', JSON.parse(sessionStorage[state.cacheKey]));
                    }
                    const response = await api.get(fetchEndpoint + pathExtension, requestConfig, contextId);
                    if (resCheck(response)) {
                        commit('fetchSuccess', response.data);
                    } else {
                        commit('fetchError', response);
                    }

                    resolve(response);
                });
            },
            fetchSingle({commit}, {id, pathExtension = '', requestConfig = {}} = {}) {
                return new Promise(async (resolve) => {
                    if (state.fetching) {
                        resolve();
                        return;
                    }

                    const response = await api.get(fetchEndpoint + pathExtension + '/' + id, requestConfig);
                    if (resCheck(response)) {
                        commit('fetchSingleSuccess', response.data);
                    } else {
                        commit('fetchSingleError', response);
                    }

                    resolve(response);
                });
            },
            fetchMultiple({commit}, {ids, pathExtension = '', requestConfig = {}} = {}) {
                return new Promise(async (resolve) => {
                    if (state.fetching) {
                        resolve();
                        return;
                    }

                    const response = await api.post(ids, fetchEndpoint + pathExtension + '/get', requestConfig);
                    if (resCheck(response)) {
                        commit('fetchMultipleSuccess', response.data);
                    } else {
                        commit('fetchMultipleError', response);
                    }

                    resolve(response);
                });
            },
            create({commit}, data) {
                const {item, pathExtension, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.post(item, pathExtension, requestConfig);
                    commit(resCheck(response) ? 'createSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            createLocal({commit}, data) {
                const {item} = data;
                commit('createSuccess', item);
            },
            createMultiple({commit}, data) {
                const {items, pathExtension, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.postMultiple(items, pathExtension, requestConfig);
                    commit(resCheck(response) ? 'createMultipleSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            patch({commit}, data) {
                const {item, pathExtension, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.patch(item, item.id, requestConfig);
                    commit(resCheck(response) ? 'patchSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            patchMultiple({commit}, data) {
                const {items, pathExtension, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.patchMultiple(items, pathExtension, requestConfig);
                    commit(resCheck(response) ? 'patchMultipleSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            update({commit}, data) {
                const {item, pathExtension, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.put(item, item.id, requestConfig);
                    commit(resCheck(response) ? 'updateSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            updateLocal({commit}, data) {
                const {item} = data;
                commit('updateSuccess', item);
            },
            updateMultiple({commit}, data) {
                const {items, pathExtension, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.putMultiple(items, pathExtension, requestConfig);
                    commit(resCheck(response) ? 'updateMultipleSuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            delete({commit}, data) {
                const {item, pathExtension, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.delete(item.id, pathExtension, requestConfig);
                    commit(resCheck(response) ? 'deleteSuccess' : 'error', resCheck(response) ? item : response.data);
                    resolve(response);
                });
            },
            deleteLocal({commit}, data) {
                const {id} = data;
                commit('deleteSuccess', { id: id});
            },
            deleteMultiple({commit}, data) {
                const {items, pathExtension, requestConfig = {}} = data;

                commit('processing');

                return new Promise(async (resolve) => {
                    const response = await api.deleteMultiple(items, pathExtension, requestConfig);
                    commit(resCheck(response) ? 'deleteMultipleSuccess' : 'error', resCheck(response) ? items : response.data);
                    resolve(response);
                });
            },
            copy({commit}, data) {
                commit('processing');

                return new Promise(async (resolve) => {
                    const {item, originalId = null, requestConfig = {}} = data;
                    if(Validator.isEmpty(originalId)) {
                        resolve();
                        return;
                    }

                    const response = await api.post(item, '?copyOfId=' + originalId, requestConfig);
                    commit(resCheck(response) ? 'copySuccess' : 'error', response.data);
                    resolve(response);
                });
            },
            reset({commit}) {
                commit('resetModule');
            }
        };

        this.mutations = {
            error(state, error) {
                state.processing = false;
                //state.fetching = false;
                state.error = error;
                state.consecutiveErrorCnt++;

                if (Vue.prototype.$notification !== undefined) {
                    Vue.prototype.$notification.show('error', error);
                }
            },
            processing(state) {
                state.processing = true;
            },
            fetching(state) {
                state.fetching = true;
            },
            fetchError(state, response) {
                state.error = response.message;
                state.fetching = false;
                state.consecutiveErrorCnt++;
                state.change = {
                    type: 'fetchedAndComputed',
                    items: []
                }
            },
            fetchFromCacheSuccess(state, items) {
                state.fetching = false;
                state.fetched = moment();
                state.consecutiveErrorCnt = 0;
                state.items = Validator.isEmpty(items) ? [] : items;
                state.change = {
                    type: 'fetchedAndComputed',
                    items: []
                }
            },
            fetchSuccess(state, items) {
                state.fetching = false;
                state.fetched = moment();
                state.consecutiveErrorCnt = 0;
                state.items = Validator.isEmpty(items) ? [] : items;
                if(state.cacheKey) {
                    try {
                        sessionStorage[state.cacheKey] = JSON.stringify(state.items);
                    } catch(e) {
                        console.error("Could not load cache data for " + state.cacheKey);
                        console.error(e);
                    }
                }
                state.change = {
                    type: 'fetchedAndComputed',
                    items: []
                }
            },
            fetchMultipleError(state, response) {
                state.error = response.message;
                state.consecutiveErrorCnt++;
                state.change = {
                    type: 'fetchMultiple',
                    items: []
                }
            },
            fetchMultipleSuccess(state, items) {
                state.consecutiveErrorCnt = 0;
                Array.updateMultiple(state.items, items, 'id');
                state.change = {
                    type: 'fetchMultiple',
                    items: []
                }
            },
            fetchSingleError(state, response) {
                state.error = response.message;
                state.consecutiveErrorCnt++;
                state.change = {
                    type: 'fetchSingle',
                    items: []
                }
            },
            fetchSingleSuccess(state, item) {
                state.fetched = moment();
                state.consecutiveErrorCnt = 0;
                Array.update(state.items, item, 'id');
                state.change = {
                    type: 'fetchSingle',
                    items: []
                }
            },
            createSuccess(state, newItem) {
                /* Push could already have created the entry */
                Array.update(state.items, newItem, 'id');
                state.consecutiveErrorCnt = 0;
                state.processing = false;
                state.change = {
                    type: 'create',
                    items: [newItem]
                }
            },
            createMultipleSuccess(state, newItems) {
                /* Push could already have created the entries */
                Array.updateMultiple(state.items, newItems, 'id');
                state.consecutiveErrorCnt = 0;
                state.processing = false;
                state.change = {
                    type: 'create',
                    items: newItems
                }
            },
            deleteSuccess(state, item) {
                Array.removeByField(state.items, 'id', item.id);
                state.consecutiveErrorCnt = 0;
                state.processing = false;
                state.change = {
                    type: 'delete',
                    items: [item]
                }
            },
            deleteMultipleSuccess(state, items) {
                Array.removeMultipleByField(state.items, 'id', items);
                state.consecutiveErrorCnt = 0;
                state.processing = false;
                state.change = {
                    type: 'delete',
                    items: items
                }
            },
            patchSuccess(state, item) {
                Array.update(state.items, item, 'id');
                state.consecutiveErrorCnt = 0;
                state.processing = false;
                state.change = {
                    type: 'patch',
                    items: [item]
                }
            },
            updateSuccess(state, item) {
                Array.update(state.items, item, 'id');
                state.consecutiveErrorCnt = 0;
                state.processing = false;
                state.change = {
                    type: 'update',
                    items: [item]
                }
            },
            updateMultipleSuccess(state, items) {
                Array.updateMultiple(state.items, items, 'id');
                state.consecutiveErrorCnt = 0;
                state.processing = false;
                state.change = {
                    type: 'update',
                    items: items
                }
            },
            patchMultipleSuccess(state, items) {
                Array.updateMultiple(state.items, items, 'id');
                state.consecutiveErrorCnt = 0;
                state.processing = false;
                state.change = {
                    type: 'patch',
                    items: items
                }
            },
            copySuccess(state) {
                /* Nothing to do - will get PUSH if this site was affected */
                state.consecutiveErrorCnt = 0;
                state.processing = false;
            },
            resetModule(state) {
                state.processing = false;
                state.items = [];
                state.fetching = false;
                state.fetched = null;
                state.error = null;
                state.consecutiveErrorCnt = 0;
                state.change = {
                    type: 'reset',
                    items: []
                }
            }
        };
    }

    cloneItems() {
        return Vue.prototype.$clone(this.state.items);
    }

    addGetters(getters) {
        this.getters = Object.assign(this.getters, getters);
    }

    addActions(actions) {
        this.actions = Object.assign(this.actions, actions);
    }

}

export default BaseCrudModule;
